import React from "react"
import ImageWithText from "./ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./logistic.module.scss"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Home/supply-logistic.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const SupplyAndLogistic = () => {
  const { image } = useStaticQuery(getImage)
  const intl = useIntl()
  return (
    <div className={styles.content}>
      <div className={styles.container}>
        <div className={styles.text__box}>
          <span className={styles.font__dinproMedium}>
            {intl.formatMessage({ id: "homepage.supply.description1" })}
            <span className={styles.font__dinproBold}>
              {intl.formatMessage({ id: "homepage.supply.description2" })}
            </span>
            .{intl.formatMessage({ id: "homepage.supply.description3" })}
            <span className={styles.font__dinproBold}>
              {intl.formatMessage({ id: "homepage.supply.success" })}
            </span>
            {intl.formatMessage({ id: "homepage.supply.description4" })}
          </span>
        </div>
        <ImageWithText
          image={image.childImageSharp.fluid}
          text={intl.formatMessage({ id: "homepage.supply.reliable" })}
        />
      </div>
      <div className={styles.background__white} />
    </div>
  )
}

export default SupplyAndLogistic
