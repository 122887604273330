// extracted by mini-css-extract-plugin
export var relative = "homepage-module--relative--2mdwe";
export var grey__bar = "homepage-module--grey__bar--2mzOP";
export var government__container = "homepage-module--government__container--13NFD";
export var image__government = "homepage-module--image__government--26W31";
export var text__government = "homepage-module--text__government--3Vh_T";
export var government__title = "homepage-module--government__title--uRiWU";
export var about__government = "homepage-module--about__government--1U8S8";
export var question__container = "homepage-module--question__container--vXrfE";
export var image__container = "homepage-module--image__container--1NUjZ";
export var image__question = "homepage-module--image__question--1ws8C";
export var image__overlay = "homepage-module--image__overlay--1ny2V";
export var image__text = "homepage-module--image__text--3nb48";
export var image__arrow = "homepage-module--image__arrow--3ecj-";
export var text__question = "homepage-module--text__question--17dVf";
export var title__question = "homepage-module--title__question--3ApyI";
export var about__question = "homepage-module--about__question--3P-vr";