import React from "react"
import "./projectsview.style.scss"
import { graphql, useStaticQuery } from "gatsby"
import ProjectsViewCard from "./ProjectsViewCard"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "PortfolioProjects" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxHeight: 195, maxWidth: 308) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`

export const ProjectsView = () => {
  const { allFile } = useStaticQuery(getImage)
  const getImageByName = name => {
    let image = null
    image = allFile.edges.find(
      file => file.node.childImageSharp.fluid.originalName === name
    )
    return image
  }
  const intl = useIntl()

  return (
    <div className="projects-view__background">
      <p className="explore__text">
        {intl.formatMessage({ id: "homepage.projects.explore" })}
      </p>
      <p className="projects__text">
        {intl.formatMessage({ id: "homepage.projects.some-projects" })}
      </p>
      <div className="projects-view__container">
        <div className="projects-view__card-component">
          <ProjectsViewCard
            key={1}
            active={true}
            image={
              getImageByName("principala_5-axis.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.service",
            })}
            description={intl.formatMessage({
              id: "portfolio.aviation-5-axis-machine.short",
            })}
            index="aviation-5-axis-machine"
          />
        </div>
        <div className="projects-view__card-component">
          <ProjectsViewCard
            key={2}
            active={true}
            image={
              getImageByName("principala_point-solar.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.service",
            })}
            description={intl.formatMessage({
              id: "portfolio.fuel-point-solar-lightning.short",
            })}
            index="fuel-point-solar-lightning"
          />
        </div>
        <div className="projects-view__card-component">
          <ProjectsViewCard
            key={3}
            active={true}
            image={
              getImageByName("principala_shipping.webp").node.childImageSharp
                .fluid
            }
            title={intl.formatMessage({
              id: "portfolio.shipping-containers.title",
            })}
            subtitle={intl.formatMessage({
              id: "portfolio.shipping-containers.service",
            })}
            description={intl.formatMessage({
              id: "portfolio.shipping-containers.short",
            })}
            index="shipping-containers"
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectsView
