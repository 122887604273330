import React from "react"
import ImageWithText from "./ImageWithText"
import { graphql, useStaticQuery } from "gatsby"
import * as styles from "./portfolio.module.scss"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Home/discover@2x.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const DiscoverPortfolio = () => {
  const { image } = useStaticQuery(getImage)
  const intl = useIntl()

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.text__container}>
          <div className={styles.font__dinpro}>
            {intl.formatMessage({ id: "homepage.discover.title" })}
          </div>
          <div className={styles.text__box}>
            <h1 className={styles.font__gotham}>
              {intl.formatMessage({ id: "homepage.discover.text" })}
            </h1>
          </div>
        </div>
        <ImageWithText
          link="portfolio"
          active={true}
          image={image.childImageSharp.fluid}
          text={intl.formatMessage({ id: "homepage.discover.picture.text" })}
        />
      </div>
    </div>
  )
}

export default DiscoverPortfolio
