// extracted by mini-css-extract-plugin
export var content = "logistic-module--content--AFsQj";
export var container = "logistic-module--container--elkuN";
export var text__box = "logistic-module--text__box--3V7QK";
export var background__white = "logistic-module--background__white--2L7iu";
export var font__dinproMedium = "logistic-module--font__dinproMedium--hCnHF";
export var font__dinproBold = "logistic-module--font__dinproBold--2V8qV";
export var image__container = "logistic-module--image__container--S2TKu";
export var image = "logistic-module--image--3xlMH";
export var image__overlay = "logistic-module--image__overlay--3xOS1";
export var image__text = "logistic-module--image__text--3x-44";