import React from "react"
import "./projectsview.style.scss"
import "../../Services/services.style.scss"
import Image from "gatsby-image"
import { Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"

const ProjectsViewCard = ({
  active,
  image,
  title,
  subtitle,
  description,
  index,
}) => {
  return (
    <Link to={"/home/portfolio/" + index}>
      <div className={active ? "card__container" : "service-card__container"}>
        <Image
          fluid={image}
          className={active ? "card__image" : "service-card__image"}
          alt="Card Image"
        />
        <div className={active ? "text__container" : "service-text__container"}>
          <div className={active ? "card__title" : "service-card__title"}>
            {title}
          </div>
          <div className={active ? "card__subtitle" : "service-card__subtitle"}>
            {subtitle}
          </div>
          <div
            className={
              active ? "card__description" : "service-card__description"
            }
          >
            {description}
          </div>
          <span className={active ? "card__button" : "service-card__button"}>
            <p className={active ? "details-button" : "service-details-button"}>
              Details
            </p>
            <FontAwesomeIcon
              icon={faLongArrowAltRight}
              color="#4E9BCF"
              className="font-awesome"
            />
          </span>
          <div
            className={active ? "line__border-sm" : "service-line__border-sm"}
          ></div>
        </div>
        <div className={active ? "line__border" : "service-line__border"}></div>
      </div>
    </Link>
  )
}

export default ProjectsViewCard
