import React from "react"
import Image from "gatsby-image"
import * as styles from "./logistic.module.scss"

const ImageWithText = ({ image, text }) => {
  return (
    <div className={styles.image__container}>
      <Image fluid={image} className={styles.image} alt="Supply Image" />
      <div className={styles.image__overlay}>
        <div className={styles.image__text}>{text}</div>
      </div>
    </div>
  )
}

export default ImageWithText
