import React from "react"
import "./supply.style.scss"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "map-01-Copy.webp" }) {
      childImageSharp {
        fluid(maxHeight: 456) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const SupplyChain = () => {
  const {
    image: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(getImage)
  const intl = useIntl()

  return (
    <div className="supply-chain_container">
      <div className="image-container">
        <Image fluid={fluid} alt="Supply Chain" />
      </div>
      <div className="supply-chain_text">
        <div className="supply-chain_title">
          {intl.formatMessage({ id: "homepage.map.title" })}
        </div>
        <div className="supply-chain_title-underline"></div>
        <p className="supply-chain_paragraph">
          {intl.formatMessage({ id: "homepage.map.description1" })}
          <span className="paragraph-bold">
            {intl.formatMessage({ id: "homepage.map.description2" })}
          </span>
          {intl.formatMessage({ id: "homepage.map.description3" })}
        </p>
      </div>
    </div>
  )
}

export default SupplyChain
