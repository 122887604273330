import React from "react"
import { useIntl } from "gatsby-plugin-intl"

export const BenefitsCard = ({
  fadedNumber,
  image,
  title,
  content,
  contentBold,
}) => {
  const intl = useIntl()

  return (
    <div className="benefits-card_component">
      <div
        className="benefit_faded-number"
        style={title === "10+" ? { border: "none" } : {}}
      >
        {fadedNumber}
      </div>
      <div
        className="benefit_img"
        style={title === "10+" ? { border: "none" } : {}}
      >
        {image}
      </div>
      <div
        className="benefit_title"
        style={title === "10+" ? { border: "none" } : {}}
      >
        {title}
      </div>
      <p className="benefit_content">
        {intl.formatMessage({ id: "aboutUs.accomplishments." + content })}{" "}
        <span className="benefit_content-bold">
          {contentBold.length !== 0 &&
            intl.formatMessage({
              id: "aboutUs.accomplishments." + contentBold,
            })}
        </span>
      </p>
    </div>
  )
}

export default BenefitsCard
