import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import * as styles from "./homepage.module.scss"
import Benefits from "./Benefits/Benefits"
import SupplyAndLogistic from "./SupplyAndLogistic/SupplyAndLogistic"
import ProjectsView from "./ProjectsView/ProjectsView"
import DiscoverPortfolio from "./DiscoverPortfolio/DiscoverPortfolio"
import SupplyChain from "./SupplyChain/SupplyChain"
import Footer from "./Footer/Footer"
import HomeHeader from "./HomepageHeader/HomepageHeader"
import SidebarMenu from "../Sidebar/SidebarMenu"
import next_arrow from "../../images/next.svg"
import { useIntl, Link } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    image: file(relativePath: { eq: "Home/government.webp" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    services: file(relativePath: { eq: "Home/question.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Homepage = () => {
  const { image, services } = useStaticQuery(getImage)
  const intl = useIntl()
  return (
    <div className={styles.homepage__container}>
      <SidebarMenu name="HOME"></SidebarMenu>
      <div>
        <HomeHeader></HomeHeader>
        <div className={styles.government__container}>
          <Image
            fluid={image.childImageSharp.fluid}
            className={styles.image__government}
            alt="Government Image"
          />
          <div className={styles.text__government}>
            <div className={styles.grey__bar}></div>
            <div className={styles.government__title}>
              {intl.formatMessage({ id: "homepage.title" })}
            </div>
            <p className={styles.about__government}>
              {intl.formatMessage({ id: "homepage.description" })}
            </p>
          </div>
        </div>
        <Benefits />

        <div className={styles.question__container}>
          <div className={styles.text__question}>
            <div className={styles.grey__bar}></div>
            <div className={styles.title__question}>
              {intl.formatMessage({ id: "homepage.title2" })}
            </div>
            <p className={styles.about__question}>
              {intl.formatMessage({ id: "homepage.description2" })}
            </p>
          </div>
          <div className={styles.image__container}>
            <Image
              fluid={services.childImageSharp.fluid}
              className={styles.image__question}
              alt="Services Image"
            />
            <div className={styles.image__overlay}>
              <Link to="/services" className={styles.image__text}>
                {intl.formatMessage({ id: "Services" })}
              </Link>
              <div className={styles.image__arrow}>
                <img
                  src={next_arrow}
                  alt="Next Arrow"
                  style={{ width: "65%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <SupplyAndLogistic />
        <ProjectsView />
        <DiscoverPortfolio />
        <SupplyChain />
        <Footer />
      </div>
    </div>
  )
}

export default Homepage
