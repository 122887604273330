import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby-plugin-intl"
import * as styles from "./portfolio.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"

const ImageWithText = ({ active, image, text, link }) => {
  return (
    <div className={active ? styles.image__container : "about__im-container"}>
      <Image
        fluid={image}
        className={active ? styles.image : "about__image"}
        alt="Image With Text"
      />
      <Link
        to={"/" + link}
        className={active ? styles.image__overlay : "about__im-overlay"}
      >
        <div className={active ? styles.image__text : "about__im-text"}>
          {text}
        </div>
        <FontAwesomeIcon
          icon={faLongArrowAltRight}
          color="#ffffff"
          className={active ? styles.image__arrow : "about__im-arrow"}
          style={{ width: "2.875em ", height: "30px" }}
        />
      </Link>
    </div>
  )
}

export default ImageWithText
