import React from "react"
import "./benefits.style.scss"
import BenefitsCard from "./BenefitsCard"
import { benefitsConst } from "../../../utilities/constants"
import Group from "../../../images/Home/group.svg"
import Agreement from "../../../images/Home/agreement.svg"
import Delivery from "../../../images/Home/delivery.svg"
import Money from "../../../images/Home/money.svg"

export const Benefits = () => {
  return (
    <div className="benefits-card_container">
      {benefitsConst.map((benefit, i) => {
        return (
          <BenefitsCard
            key={i}
            fadedNumber={benefit.fadedNumber}
            image={
              <img
                src={
                  i === 0
                    ? [Group]
                    : i === 1
                    ? [Agreement]
                    : i === 2
                    ? [Delivery]
                    : [Money]
                }
                alt="benefit-img"
                className="benefit-card_image"
              />
            }
            title={benefit.title}
            content={benefit.content}
            contentBold={benefit.contentBold}
          />
        )
      })}
    </div>
  )
}

export default Benefits
