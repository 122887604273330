import React from "react"
import * as styles from "./global.module.scss"
import { Helmet } from "react-helmet"
import Homepage from "../components/Homepage/Homepage"
import GoogleTagManager from "../components/GoogleTagManager/GoogleTagManager"

export default function AboutUs() {
  return (
    <div>
      <GoogleTagManager />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Competdac - Home</title>
        <meta
          name="description"
          content="COMPETDAC means logistic support from transportation to material handling and also maintenance and repairs, construction and equipment supply. Our main goal is to provide quality transportation services for cargo and passengers as well"
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
      </Helmet>
      <div className={styles.container}>
        <Homepage />
      </div>
    </div>
  )
}
