import React from "react"
import * as styles from "../../Header/header.module.scss"
import Header from "../../Header/Header"
import Typing, { Reset } from "react-typing-animation"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const getImage = graphql`
  {
    imageDesktop: file(relativePath: { eq: "Hero/hero_fagure.webp" }) {
      childImageSharp {
        fluid(maxWidth: 7400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageMobile: file(relativePath: { eq: "Hero/fagure_mobile.webp" }) {
      childImageSharp {
        fluid(maxHeight: 7400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const HomeHeader = () => {
  const { imageDesktop, imageMobile } = useStaticQuery(getImage)
  const intl = useIntl()
  return (
    <div>
      <div className={styles.header__desktop}>
        <Header
          isHomepage={true}
          image={imageDesktop.childImageSharp.fluid}
          pageName={[
            intl.formatMessage({ id: "homepage.header.ARMY DEMANDS" }),
            <br />,
            intl.formatMessage({ id: "homepage.header.WE DELIVER" }),
            <br />,
            <span id={styles.transparentText}>
              {intl.formatMessage({ id: "TRANSPARENT" })}
            </span>,
            <Typing loop speed={50} className={styles.features}>
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "TRANSPORTATION" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "CONSTRUCTION" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "IT&C" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "SUPPLY CHAIN" })}
              </span>
              <Reset delay={750} />
            </Typing>,
          ]}
        ></Header>
      </div>
      <div className={styles.header__mobile}>
        <Header
          isHomepage={true}
          image={imageMobile.childImageSharp.fluid}
          pageName={[
            intl.formatMessage({ id: "homepage.header.ARMY DEMANDS" }),
            <br />,
            intl.formatMessage({ id: "homepage.header.WE DELIVER" }),
            <br />,
            <span id={styles.transparentText}>
              {intl.formatMessage({ id: "TRANSPARENT" })}
            </span>,
            <Typing loop speed={50} className={styles.features}>
              <span id={styles.animateText}>
                {" "}
                {intl.formatMessage({ id: "TRANSPORTATION" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "CONSTRUCTION" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "IT&C" })}
              </span>
              <Reset count={1} delay={750} />
              <span id={styles.animateText}>
                {intl.formatMessage({ id: "SUPPLY CHAIN" })}
              </span>
              <Reset delay={750} />
            </Typing>,
          ]}
        ></Header>
      </div>
    </div>
  )
}

export default HomeHeader
