// extracted by mini-css-extract-plugin
export var background = "portfolio-module--background--lZvO3";
export var container = "portfolio-module--container--3krj1";
export var image__container = "portfolio-module--image__container--1K3y3";
export var image = "portfolio-module--image--RBfSj";
export var image__overlay = "portfolio-module--image__overlay--1QV8k";
export var image__text = "portfolio-module--image__text--2EGWX";
export var image__arrow = "portfolio-module--image__arrow--3RZ_2";
export var svgInlineFa = "portfolio-module--svg-inline--fa--1qnW3";
export var faW14 = "portfolio-module--fa-w-14--2cEG-";
export var font__dinpro = "portfolio-module--font__dinpro--1Md6q";
export var font__gotham = "portfolio-module--font__gotham--m7lgY";
export var text__box = "portfolio-module--text__box--1c09l";
export var text__container = "portfolio-module--text__container--1f9pC";
export var font_awesome = "portfolio-module--font_awesome--GYFYc";